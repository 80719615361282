import { formatCurrency, getActiveCurrency } from "../../util/currency";

// Watermark Settings
const LIGHT_WATERMARK_URL = "https://static.coingecko.com/gecko-logo-full-new-black.png";
const DARK_WATERMARK_URL = "https://static.coingecko.com/gecko-logo-full-new-white.png";
const WATERMARK_WIDTH = 87.8;
const WATERMARK_HEIGHT = 20;
const WATERMARK_PADDING = 10;


export const buildCategoryHeatmapConfig = (data, element, currencyCode = getActiveCurrency(), heatmapType = "category") => {
  return {
    title: false,
    exporting: false,
    navigator: false,
    rangeSelector: false,
    scrollbar: false,
    credits: false,
    legend: false,

    chart: {
      events: {
        render: (e) => {
          const chart = e.target;
          const x = WATERMARK_PADDING;
          const y = chart.plotHeight - WATERMARK_HEIGHT - WATERMARK_PADDING;

          if (!chart.watermark) {
            chart.watermark = chart.renderer.image(LIGHT_WATERMARK_URL, 0, 0, WATERMARK_WIDTH, WATERMARK_HEIGHT)
                                            .addClass("tw-opacity-50 dark:tw-opacity-30 dark:tw-hidden")
                                            .attr({ zIndex: 3 }).add();
          }

          if (!chart.darkWatermark) {
            chart.darkWatermark = chart.renderer.image(DARK_WATERMARK_URL, 0, 0, WATERMARK_WIDTH, WATERMARK_HEIGHT)
                                            .addClass("tw-opacity-50 dark:tw-opacity-30 tw-hidden dark:tw-block")
                                            .attr({ zIndex: 3 }).add();
          }

          chart.watermark.attr({ x, y });
          chart.darkWatermark.attr({ x, y });
        }
      },
      renderTo: element,
      margin: [0, 0, 0, 0]
    },

    colorAxis: {
      dataClasses: [
        { from: -Infinity, to: -20, color: "#7A092C" },
        { from: -20, to: -5, color: "#B7192F" },
        { from: -5, to: 0, color: "#FF3A33" },
        { from: 0, to: 5, color: "#00A83E" },
        { from: 5, to: 20, color: "#007844" },
        { from: 20, to: Infinity, color: "#00503D" }
      ]
    },

    plotOptions: { treemap: { animation: false } },
    series: [
      {
        type: "treemap",
        data: data,
        layoutAlgorithm: "squarified",
        dataLabels: {
          align: "left",
          verticalAlign: "top",
          overflow: "none",
          useHTML: true,
          formatter: function() {
            return buildLabel(this.point);
          }
        }
      }
    ],

    tooltip: {
      useHTML: true,
      borderRadius: 16,
      padding: 0.5,
      style: {
        textAlign: "center",
        fontSize: "1rem"
      },
      formatter: function () {
        return buildTooltip(this.point, currencyCode);
      }
    }
  };
};


export const buildLabel = (data) => {
  const percentChange = parseFloat(data.colorValue).toFixed(2);
  const percentChangeText = percentChange !== "NaN" ? `${percentChange > 0 ? "+" : ""}${percentChange}%` : "-";

  return `
    <div>
      <div class="tw-flex-grow tw-text-xs tw-leading-4 tw-text-white tw-font-semibold tw-text-wrap">${data.name}</div>
      <div class="tw-text-xs tw-leading-4 tw-text-white-500 tw-font-light">${percentChangeText}</div>
    </div>
  `;
};


export const buildTooltip = (data, currencyCode) => {
  const volume = formatCurrency(data.volume, currencyCode, false, true, true);
  const marketCap = formatCurrency(data.value, currencyCode, false, true, true);

  return `
    <div class="gecko-tooltip">
      <div class="title">${data.name}</div>
      <div>
        ${I18n.t("charts.market_cap")}:
        <span class="value">${marketCap}</span>
        </div>
      <div>
        ${I18n.t("charts.volume")}:
        <span class="value">${volume}</span>
      </div>
    </div>
  `;
};
